@charset "UTF-8";
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * nimbus-sans:
 *   - http://typekit.com/eulas/00000000000000000001518d
 *   - http://typekit.com/eulas/00000000000000000001011f
 *   - http://typekit.com/eulas/000000000000000000012544
 *   - http://typekit.com/eulas/000000000000000000010121
 * nimbus-sans-condensed:
 *   - http://typekit.com/eulas/00000000000000003b9b29b2
 *   - http://typekit.com/eulas/00000000000000003b9b29b5
 * nimbus-sans-extended:
 *   - http://typekit.com/eulas/00000000000000003b9af903
 *   - http://typekit.com/eulas/00000000000000003b9af904
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-05-27 12:57:27 UTC"}*/
/* @import url("https://p.typekit.net/p.css?s=1&k=rfs8mnk&ht=tk&f=10793.10794.10795.10796.10801.10802.10804.10805&a=4089652&app=typekit&e=css");
@font-face {
  font-family: "nimbus-sans";
  src: url("https://use.typekit.net/af/da0c19/00000000000000000001518d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/da0c19/00000000000000000001518d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/da0c19/00000000000000000001518d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "nimbus-sans";
  src: url("https://use.typekit.net/af/7ba522/00000000000000000001011f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/7ba522/00000000000000000001011f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/7ba522/00000000000000000001011f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400; }

@font-face {
  font-family: "nimbus-sans";
  src: url("https://use.typekit.net/af/360cf4/000000000000000000012544/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/360cf4/000000000000000000012544/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/360cf4/000000000000000000012544/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: "nimbus-sans";
  src: url("https://use.typekit.net/af/a44ffc/000000000000000000010121/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/a44ffc/000000000000000000010121/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/a44ffc/000000000000000000010121/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700; }

@font-face {
  font-family: "nimbus-sans-condensed";
  src: url("https://use.typekit.net/af/6125b7/00000000000000003b9b29b2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/6125b7/00000000000000003b9b29b2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/6125b7/00000000000000003b9b29b2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "nimbus-sans-condensed";
  src: url("https://use.typekit.net/af/f6c05e/00000000000000003b9b29b5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/f6c05e/00000000000000003b9b29b5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/f6c05e/00000000000000003b9b29b5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: "nimbus-sans-extended";
  src: url("https://use.typekit.net/af/8d484d/00000000000000003b9af903/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/8d484d/00000000000000003b9af903/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/8d484d/00000000000000003b9af903/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "nimbus-sans-extended";
  src: url("https://use.typekit.net/af/b13282/00000000000000003b9af904/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/b13282/00000000000000003b9af904/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/b13282/00000000000000003b9af904/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700; } */

body {
  color: #f2f2f2;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
}

.pointer {
  cursor: pointer;
}

.gplusfacebook {
  color: #084a59;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  font-weight: bold;
}

.text-white {
  color: #f2f2f2 !important;
}

.w-70 {
  width: 70%;
}

.sbmBtn {
  width: 70%;
}

.bg-green {
  background-color: #084a59 !important;
  color: #f2f2f2 !important;
  padding-left: 46px;
  padding-right: 46px;
  /* padding-top: 60px;
  padding-bottom: 60px; */
}

.bg-white {
  background-color: #f2f2f2 !important;
  color: #084a59 !important;
  padding-left: 46px;
  padding-right: 46px;
  /* padding-top: 60px;
  padding-bottom: 60px;  */
}

.gp-btn-pink {
  background: #f13067 !important;
  color: #f2f2f2;
  font-size: 14px;
  line-height: 17px;
  border-radius: 0;
  padding: 10px 20px;
  font-weight: 700;
  letter-spacing: 0;
}

.gp-btn-pink:hover {
  background: #f13067 !important;
  color: #f2f2f2;
  font-size: 14px;
  line-height: 17px;
  border-radius: 0;
  padding: 10px 20px;
  font-weight: 700;
  letter-spacing: 0;
}

.inpt {
  background: none;
  border: 1px solid #d1caca;
  padding: 12px 20px;
  color: #d1caca;
  width: 70%;
  letter-spacing: 1px;
}
.inpt:focus {
  border: 1px solid #d1caca;
  box-shadow: 0 none;
  outline: 0 none;
}
.inpt::placeholder {
  color: #d1caca;
}

.gallery {
  margin-top: 35px;
  margin-bottom: 16px;
}
.gallery__wrapper:hover {
  color: inherit;
  text-decoration: none;
}
.gallery__item img {
  width: 100%;
}
.gallery__item:not(:last-child) {
  margin-right: 68px;
}
.gallery__item-info {
  margin-top: 26px;
  text-align: left;
  color: #084a59;
  letter-spacing: 0;
}
.gallery__item-info-header {
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
}
.gallery__item-info-desc {
  font-size: 16px;
  line-height: 24px;
  margin-top: 0.5rem;
}

.carousel {
  width: 1366px;
  height: auto;
  position: relative;
  margin: auto;
  cursor: url('../img/next.svg'), auto;
}
.carousel__header {
  position: absolute;
  z-index: 100;
  padding-left: 128px;
  padding-right: 128px;
  padding-top: 48px;
  padding-bottom: 48px;
}
.carousel__footer {
  position: absolute;
  z-index: 100;
  bottom: 48px;
  padding-right: 128px;
}
.carousel__next {
  position: absolute;
  z-index: 100;
  /* bottom: 148px; */
  padding-right: 305px;
  top: 45%;
}
.carousel__inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel__inner-item {
  position: relative;
  display: none;
  width: 100%;
  float: left;
}
.carousel__inner-item img {
  width: 1009px;
  height: 768px;
}
.carousel__inner-item.active {
  position: absolute;
  display: block;
  float: left;
  width: auto;
  width: 80%;
  z-index: 99;
  filter: grayscale(0%);
  transform: translateX(0px);
  transition: all 1s ease-out;
}
.carousel__inner-item.activeback {
  position: absolute;
  display: block;
  float: left;
  width: auto;
  width: 80%;
  z-index: 98;
  transform: translateX(-500px);
  transition: all 1s ease-out;
}

.carousel__inner-item.preview {
  position: relative;
  display: block;
  float: right;
  width: auto;
  margin-top: 0px;
  filter: grayscale(100%);
  transform: translateX(652px);
}

.header {
  position: relative;
  background-color: black;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
.header__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}
.header__subject {
  display: flex;
  align-items: flex-end;
  color: #f2f2f2;
}
.header__subject-item {
  font-size: 20px;
  font-weight: 700;
}
.header__subject-item:not(:last-child) {
  border-right: 1px solid #89cad9;
}
.header video {
  width: 100% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  /*max-width: 1370px;*/
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.header .container {
  position: relative;
  z-index: 2;
}

.about {
  background-color: #084a59;
}
.about__header {
  font-family: 'Anton', sans-serif;
  font-size: 80px;
  font-weight: 500;
  line-height: 100px;
  letter-spacing: 2.13px;
  color: #f2f2f2;
  max-width: 625px;
}
.about__small {
  color: #89cad9;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: normal;
  margin-top: 43px;
}
.about__small {
  max-width: 625px;
}
.about__more {
  margin-top: 36px;
}
.about__more-text {
  width: 126px;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: normal;
  color: #f2f2f2;
  font-weight: 700;
}
.about__more-line {
  width: 127px;
  border-top: 3px solid #f2f2f2;
  margin-top: 5px;
}

.news {
  background-color: #f2f2f2;
  color: #084a59;
}
.news__header {
  margin-top: 1.5rem;
  line-height: 93px;
  flex-flow: row;
}
.news__header-title {
  font-family: 'Anton', sans-serif;
  font-size: 90px;
  letter-spacing: 2.13px;
  font-weight: bold;
  margin-bottom: 35px;
}
.news__header-paggination {
  font-size: 60px;
  letter-spacing: 1.28px;
}
.news__header-paggination-arrow {
  margin-left: 9px;
}

.projects {
  background-color: #084a59;
  color: #f2f2f2;
}
.projects__header {
  margin-top: 1.5rem;
  line-height: 93px;
}
.projects__header-title {
  font-size: 100px;
  letter-spacing: 2.13px;
  font-weight: bold;
  text-align: left;
}
.projects__header-paggination {
  font-size: 60px;
  letter-spacing: 1.28px;
}
.projects__header-paggination-arrow {
  margin-left: 9px;
}

.contact {
  background-color: #363333;
}
.contact__header-title {
  font-family: 'Anton', sans-serif;
  font-size: 90px;
  line-height: 93px;
  letter-spacing: 2.13px;
  color: #89cad9;
}
.contact__desc-header {
  font-size: 18px;
  font-weight: 700;
  color: #f2f2f2;
}

html {
  scroll-behavior: smooth;
}

.img-pus {
  transition: transform 0.5s;
}
.img-pus:hover {
  transform: rotate(45deg);
}
.img-close {
  transition: transform 0.8s ease-in-out;
}

.trans-right {
  opacity: 0;
  /* margin-left:-200px; */
  transition: all 1s ease-out;
}

.trans-right-end {
  opacity: 1 !important;
  transform: translateX(100px);
}

.trans-appear-end {
  opacity: 1 !important;
  transition: all 2s ease-out !important;
}

.trans-appear {
  opacity: 0;
  transition: all 3s ease-out;
}

.trans-appear-box1 {
  opacity: 0;
  transition: all 1s ease-out;
}

.trans-appear-box2 {
  opacity: 0;
  transition: all 1s 0.5s ease-out;
}

.trans-appear-box3 {
  opacity: 0;
  transition: all 1s 1s ease-out;
}

.blink {
  animation: blink 2s step-end infinite;
}

@keyframes blink {
  40% {
    visibility: hidden;
  }
}

.carousel-item {
  order: 3;
}
.carousel-item.active {
  width: 80%;
  order: 1;
  z-index: 3;
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.carousel-item.preview {
  display: block;
  width: 80%;
  margin-left: 80%;
  order: 2;
  z-index: 1;
  -webkit-animation: slide-left-prev 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-left-prev 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.carousel-item.last {
  display: block;
  width: 80%;
  margin-left: -100%;
  order: 3;
  z-index: 1;
  -webkit-animation: slide-left-prev 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-left-prev 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.casting__container {
  width: 100%;
  max-width: 100%;
  padding-left: 44px;
  padding-right: 71px;
}

.plusIcon {
  position: fixed;
  right: 71px;
}

.casting-line {
  margin-top: -20px;
  width: 120%;
  margin-left: -5%;
  font-size: 50px;
  max-height: 60px;
  line-height: 150px;
}

.footer-item {
  min-height: 16px;
  font-size: 14px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-right: 1px solid #f2f2f2;
}

.footer-item:last-child {
  border-right: none;
}

.just-cast-info {
  font-size: 100px;
  letter-spacing: 2.13px;
  width: 100%;
  display: flex;
}

#gppIcon {
  left: 71px;
}

.paddd {
  padding-left: 44px;
  padding-right: 71px;
}

.blurPage__top {
  position: absolute;
  top: 20px;
  right: 71px;
  z-index: 999;
  /* padding-top: 3rem; */
}

.blurPage__menu {
  position: absolute;
  top: 15%;
  left: 10%;
  font-size: 100px;
  text-transform: uppercase;
  letter-spacing: 2.13px;
  color: #89cad9;
  display: flex;
}

.blurPage__menu--item {
  font-family: 'Anton', sans-serif;
  font-size: 90px;
  letter-spacing: 2.13px;
  font-weight: bold;
}

.blurPage__menuBottom {
  position: absolute;
  bottom: 5%;
  left: 10%;
}

.contact-padding {
  padding-left: 46px;
}

.font-anton {
  font-family: 'Anton', sans-serif;
}

/* .boldFont{
  font-family: 'Anton', sans-serif;
  font-weight: 700;
} */

.section-title {
  font-family: 'Anton', sans-serif;
  font-size: 70px;
  font-weight: 500;
  letter-spacing: 2.13px;
}

.blurPage {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  /* background-color: rgba(0, 0, 0, .32); */
  background-color: rgba(54, 51, 51, 0.35);
}

.isBlur {
  filter: blur(2rem);
  transition: all 1s ease-out;
}

.contact-mini {
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  cursor: pointer;
  position: fixed;
  right: 71px;
  bottom: 3rem;
}

.headerImg {
  width: auto;
  float: left;
}

.playIcon {
  width: auto;
}
.wwa--img {
  width: 80%;
}

.mw-item {
  max-width: 80%;
}

.castings {
  border: 0px;
  width: 100%;
}

@media only screen and (max-width: 1500px) {
  .header video {
    width: auto !important;
  }
}

@media only screen and (max-width: 991px) {
  .mw-item {
    max-width: 100%;
  }
  .headerImg {
    width: 80%;
  }
  .playIcon {
    width: 30%;
  }
  .lh-xs {
    line-height: 66px !important;
  }
  .wwa--img {
    width: 100%;
    padding-top: 0.5rem;
  }
  .contact-mini {
    position: absolute;
    right: 10px;
    bottom: 1rem;
  }
  .casting__container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .plusIcon {
    right: 10px;
  }
  .about__header {
    font-family: 'Anton', sans-serif;
    font-size: 50px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 1.28px;
  }
  .casting-line {
    font-size: 30px;
  }
  .section-title {
    font-family: 'Anton', sans-serif;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 1.28px;
  }
  .news__header {
    margin-top: 1.5rem;
    flex-flow: column;
  }
  .news__header-title {
    font-size: 50px;
    letter-spacing: 1.28px;
    font-weight: bold;
  }
  .blurPage__menu--item {
    font-size: 50px;
    letter-spacing: 1.28px;
  }
  .news__header-paggination {
    font-size: 40px;
    letter-spacing: 1.28px;
    justify-content: space-between;
  }
  .news__header-paggination-arrow {
    margin-left: 9px;
  }
  .inpt {
    width: 100%;
  }
  .sbmBtn {
    width: 100%;
  }
  .contact__header-title {
    font-size: 50px;
  }
  .footer-item:nth-last-child(2) {
    border-right: none;
  }
  .xs-center {
    justify-content: center;
  }
  .xs-text-center {
    text-align: center !important;
  }
  .just-cast-info {
    font-size: 60px;
  }
  .bg-green {
    padding-left: 10px;
    padding-right: 10px;
  }
  .bg-white {
    padding-left: 10px;
    padding-right: 10px;
  }
  #gppIcon {
    left: 10px;
  }
  .paddd {
    padding-left: 10px;
    padding-right: 10px;
  }
  .blurPage__top {
    right: 10px;
  }
  .blurPage__menu {
    font-size: 60px;
  }
  .blurPage__menuBottom {
    bottom: 12%;
  }
  .contact-padding {
    padding-left: 10px;
  }
  .header video {
    width: auto !important;
  }
  .header video.full-width {
    width: 100% !important;
  }
  .header__subject-item {
    font-size: 14px;
  }
  .pl-xs-0 {
    padding-left: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-8-22 17:45:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes slide-left-prev {
  0% {
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-left-prev {
  0% {
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
